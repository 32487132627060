import { useCallback } from 'react';

import { useApolloClient } from '@apollo/client';
import { useAuth } from '@teamexos/fit-shared';
import { useNavigate } from 'react-router';
import { mixpanelClient } from '../utils/mixpanelClient';

const useLogout = () => {
  const client = useApolloClient();
  const navigate = useNavigate();
  const { signOut } = useAuth();

  const logout = useCallback(async () => {
    await signOut(false);
    await client.clearStore();
    mixpanelClient.reset();
    navigate('/app/login');
  }, [client, navigate, signOut]);

  return logout;
};

export default useLogout;
