import React from 'react';

import { Link, useMatch } from 'react-router';
import styled from 'styled-components';
import useTracking from '../../../hooks/useTracking';
import { BLACK, WHITE } from '../../../theme';
import {
  EventCategories,
  EventLabels,
  EventNames,
} from '../../../utils/trackingEvents';
import Text, { TextColor, TextKind } from '../Text';

export const NavigationWrapper = styled.nav`
  display: flex;
  height: 100%;
`;

const StyledLink = styled(Link)<{ $isActive: boolean }>`
  display: flex;
  height: 100%;
  min-width: 68px;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  box-sizing: border-box;
  border-bottom: ${({ $isActive }) => `3px solid ${$isActive ? WHITE : BLACK}`};
  padding-top: 3px;
  padding-left: 16px;
  padding-right: 16px;
`;

interface Props {
  children: string;
  to: string;
  testId: string;
  eventLabel: EventLabels;
  show?: boolean;
}

export const NavigationItem: React.FC<Props> = ({
  children,
  to,
  testId,
  eventLabel,
  show = true,
}: Props) => {
  const isActive = !!useMatch(to);
  const { track } = useTracking();

  if (!show) {
    return null;
  }

  const onClick = () => {
    track(EventNames.NavTabClick, {
      event_category: EventCategories.Navigation,
      event_label: eventLabel,
    });
  };

  return (
    <StyledLink
      to={to}
      $isActive={isActive}
      aria-current={isActive}
      data-testid={testId}
      onClick={onClick}
    >
      <Text
        kind={TextKind.BodyM}
        color={TextColor.Contrast}
        component="span"
        bold={isActive}
      >
        {children}
      </Text>
    </StyledLink>
  );
};
