import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';
import mixpanel, { Dict } from 'mixpanel-browser';

const isProductionBuild = process.env.NODE_ENV === 'production';
const isTestEnvironment = process.env.NODE_ENV === 'test';

const mixpanelApiToken = process.env.GATSBY_MIXPANEL_TOKEN;
let isInitialized = false;

if (isProductionBuild && mixpanelApiToken) {
  mixpanel.init(mixpanelApiToken);
  isInitialized = true;
} else if (isProductionBuild && !mixpanelApiToken) {
  datadogRum.addError(new Error('Mixpanel not initialized: missing API token'));
  Sentry.captureMessage(
    'Mixpanel not initialized: missing API token',
    'warning',
  );
}

export const mixpanelClient = {
  track: (name: string, props: Dict, cb: () => void) => {
    if (isProductionBuild && isInitialized) {
      mixpanel.track(name, props, cb);
    }

    if (!isProductionBuild && !isTestEnvironment) {
      // biome-ignore lint/suspicious/noConsole: eslint migration
      console.log('Mixpanel track:', { isInitialized, name, props });
      cb();
    }
  },
  identify: (id: string) => {
    if (isProductionBuild && isInitialized) {
      mixpanel.identify(id);
    }

    if (!isProductionBuild && !isTestEnvironment) {
      // biome-ignore lint/suspicious/noConsole: eslint migration
      console.log('Mixpanel identify:', { isInitialized, id });
    }
  },
  reset: () => {
    if (isProductionBuild && isInitialized) {
      mixpanel.reset();
    }

    if (!isProductionBuild && !isTestEnvironment) {
      // biome-ignore lint/suspicious/noConsole: eslint migration
      console.log('Mixpanel reset:', { isInitialized });
    }
  },
};
