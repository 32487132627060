import { useEffect, useState } from 'react';

/*
Usage:
const [value, setValue] = useLocalStorage(key, initialValue);
*/

function useLocalStorage<T>(key: string, value: T) {
  const [valueInState, setValueInState] = useState<T | null>(value);

  const setValueFromLocalStorage = () => {
    if (typeof window === undefined) {
      return value;
    }

    const valueInLocalStorage = window.localStorage.getItem(key);
    return valueInLocalStorage ? JSON.parse(valueInLocalStorage) : value;
  };

  const setValue = (newValue: T | null, keyOverride?: string) => {
    setValueInState(newValue);

    if (typeof window !== 'undefined') {
      if (newValue === null) {
        window.localStorage.removeItem(keyOverride || key);
      } else {
        window.localStorage.setItem(
          keyOverride || key,
          JSON.stringify(newValue),
        );
      }
      const event = new Event('itemInserted');
      document.dispatchEvent(event);
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: adding setValueFromLocalStorage here can cause infinite loop, even if it's wrapped in useCallback
  useEffect(() => {
    setValueInState(setValueFromLocalStorage());
  }, [key, setValueInState]);

  useEffect(() => {
    // This is a custom event that gets dispatched when an item is inserted into local storage
    // so that local storage acts more like state
    const updateLocalStorage = () => {
      setValueInState(setValueFromLocalStorage());
    };
    document.addEventListener('itemInserted', updateLocalStorage, false);
    return () => {
      document.removeEventListener('itemInserted', updateLocalStorage, false);
    };
  });

  return [valueInState, setValue] as const;
}

export default useLocalStorage;
