import React from 'react';

import MuiTooltip, {
  tooltipClasses,
  TooltipProps,
} from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { BLACK2, WHITE } from '../../theme';

interface Props {
  children: React.ReactNode;
  content: NonNullable<React.ReactNode>;
  placement?: 'top' | 'top-start' | 'bottom';
  maxWidth?: number;
  disabled?: boolean;
}

const TooltipComponent: React.FC<TooltipProps> = ({ className, ...props }) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
);
const Container = styled(TooltipComponent)<{
  $maxwidth: number | undefined;
}>(({ $maxwidth }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: BLACK2,
    color: WHITE,
    borderRadius: 4,
    padding: '4px 8px',
    maxWidth: $maxwidth || 'none',
    boxShadow: 'none',
  },
  [`& .${tooltipClasses.tooltip}.${tooltipClasses.tooltipPlacementBottom}`]: {
    marginBottom: '4px',
  },
}));

const Tooltip: React.FC<Props> = ({
  children,
  content,
  placement,
  maxWidth,
  disabled = false,
}) => (
  <Container
    title={disabled ? '' : content}
    placement={placement}
    $maxwidth={maxWidth}
  >
    <span>{children}</span>
  </Container>
);

export default Tooltip;
