import React from 'react';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import camelCase from 'lodash/camelCase';
import styled from 'styled-components';
import { BLACK, BORDER_GRAY, SECONDARY_HOVER_GRAY, WHITE } from '../theme';
import Text, { TextColor, TextKind } from './reusable/Text';

const MEMBER_PANEL_PADDING = '16px'; // spacing around the perimeter of the panel
const MEMBER_PANEL_CONTENT_SPACING = '16px'; // spacing between elements in the panel

interface MemberPanelTitleProps {
  large?: boolean;
  children?: React.ReactNode;
  testId?: string;
}

const MemberPanelTitle: React.FC<MemberPanelTitleProps> = ({
  large,
  testId,
  children,
}) => (
  <Text
    kind={large ? TextKind.TitleXs : TextKind.BodySM}
    component="h2"
    color={large ? TextColor.Primary : TextColor.Secondary}
    testId={testId}
  >
    {children}
  </Text>
);

const StyledMemberPanelAddButton = styled.button`
  display: flex;
  justify-content: center;

  cursor: pointer;

  background: transparent;
  border: none;

  &:active,
  &:hover {
    background-color: ${SECONDARY_HOVER_GRAY};
  }

  padding: 8px 16px;
  gap: 12px;
`;

const MemberPanelAddButton: React.FC<{
  text: string;
  onClick: () => void;
}> = ({ text, onClick }) => (
  <StyledMemberPanelAddButton
    onClick={onClick}
    data-testid={`${camelCase(text)}-button`}
  >
    <Text kind={TextKind.ButtonL} component="span">
      {text}
    </Text>
    <FontAwesomeIcon color={BLACK} icon={faPlus} />
  </StyledMemberPanelAddButton>
);

const MemberPanelHeader = styled.header<{ $noMargin?: boolean }>`
  padding: 0 ${MEMBER_PANEL_PADDING};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ $noMargin }) =>
    $noMargin ? 0 : MEMBER_PANEL_CONTENT_SPACING};
`;

const MemberPanelContentSection = styled.section<{
  $centered?: boolean;
  $noMargin?: boolean;
  $horizontal?: boolean;
}>`
  padding: 0 ${MEMBER_PANEL_PADDING};
  margin-bottom: ${({ $noMargin }) =>
    $noMargin ? '0' : MEMBER_PANEL_CONTENT_SPACING};
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${({ $centered }) =>
    $centered &&
    `
    justify-content: center;
    align-items: center;
  `}

  ${({ $horizontal }) => ($horizontal ? `flex-direction: row;` : '')}
`;

const MemberPanelDataList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const MemberPanelDataListItem = styled.li`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  margin-bottom: 4px;
`;

export enum MemberPanelVariant {
  DEFAULT = 'DEFAULT',
  BUTTON = 'BUTTON',
}

const StyledMemberPanel = styled.section<MemberPanelProps>`
  background-color: ${WHITE};
  border: 1px solid ${BORDER_GRAY};
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  ${({ $variant }) => {
    switch ($variant) {
      case MemberPanelVariant.BUTTON:
        return `padding: 0;`;
      case MemberPanelVariant.DEFAULT:
      default:
        return `padding: ${MEMBER_PANEL_PADDING} 0 0 0;`;
    }
  }}
`;

interface MemberPanelProps extends React.PropsWithChildren {
  $variant?: MemberPanelVariant;
  className?: string;
  testId?: string;
}

interface MemberPanelInterface extends React.FC<MemberPanelProps> {
  Title: typeof MemberPanelTitle;
  Header: typeof MemberPanelHeader;
  ContentSection: typeof MemberPanelContentSection;
  DataList: typeof MemberPanelDataList;
  DataListItem: typeof MemberPanelDataListItem;
  AddButton: typeof MemberPanelAddButton;
}

/**
 * This is defined as a composite component, which is a pattern that allows
 * for nesting of components within each other. This increases coupling and
 * reduces the likelyhood of invalid usage of children components.
 *
 * See src/components/MemberProgram/index.tsx for example usage.
 */
export const MemberPanel: MemberPanelInterface = ({
  $variant,
  className,
  children,
  testId,
}) => (
  <StyledMemberPanel
    className={className}
    $variant={$variant}
    data-testid={testId}
  >
    {children}
  </StyledMemberPanel>
);

MemberPanel.Title = MemberPanelTitle;
MemberPanel.Header = MemberPanelHeader;
MemberPanel.ContentSection = MemberPanelContentSection;
MemberPanel.DataList = MemberPanelDataList;
MemberPanel.DataListItem = MemberPanelDataListItem;
MemberPanel.AddButton = MemberPanelAddButton;
