import React from 'react';

import { useAuth } from '@teamexos/fit-shared';
import { Navigate, useLocation } from 'react-router';
import { CoachType, useSelfQuery } from '../graphql/types';
import { LoadingPage } from './reusable/LoadingPage';

const PrivateRoute: React.FC<{
  allowedCoachTypes?: Array<CoachType>;
  children: React.ReactNode;
}> = ({ allowedCoachTypes, children }) => {
  const { isLoggedIn, isLoading: authIsLoading } = useAuth();

  const { data, loading, error } = useSelfQuery({
    fetchPolicy: 'cache-first',
    skip: !isLoggedIn || authIsLoading,
  });

  const isMember = !data?.self?.isAdmin && !data?.self?.isCoach;
  const coachType = data?.self?.coachData?.type;
  const { pathname } = useLocation();

  if ((!authIsLoading && !isLoggedIn) || error) {
    return <Navigate to="/app/login" replace />;
  }

  if (loading || authIsLoading || !isLoggedIn) {
    return <LoadingPage />;
  }

  if (
    allowedCoachTypes &&
    (!coachType || !allowedCoachTypes.includes(coachType))
  ) {
    // Coaches of this type don't have access to the requested route and are forced back to the main screen
    return <Navigate to="/app" replace />;
  }

  if (isMember && pathname !== '/app' && pathname !== '/app/') {
    // Non-coach members are forced to the main screen which displays the member gate notice
    return <Navigate to="/app" replace />;
  }

  return children;
};

export default PrivateRoute;
