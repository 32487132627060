import React from 'react';

import { CircularProgress } from '@mui/material';
import styled from 'styled-components';
import { BLACK2 } from '../theme';
import { MemberPanel } from './MemberPanel';

const LoadingPanelContainer = styled(MemberPanel)<{
  $noBorder?: boolean;
  $height?: number;
  $fullHeight?: boolean;
  $backgroundColor?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  color: ${BLACK2};

  ${({ $backgroundColor }) =>
    $backgroundColor ? `background-color: ${$backgroundColor}` : ''};
  ${({ $height }) => ($height ? `min-height: ${$height}px` : '')};
  ${({ $fullHeight }) => ($fullHeight ? `height: 100%` : '')};
  ${({ $noBorder }) => ($noBorder ? `border: none` : '')};
`;

type LoadingPanelProps = {
  height?: number;
  fullHeight?: boolean;
  noBorder?: boolean;
  testId?: string;
  backgroundColor?: string;
};

/**
 * @TODO
 * This component should be decoupled from the MemberPanel component.
 * It should only contain a spinner, height prop, and nothing else.
 * This component should take full width and center the spinner.
 */
const LoadingPanel: React.FC<LoadingPanelProps> = ({
  height = 100,
  fullHeight = false,
  noBorder,
  testId,
  backgroundColor,
}) => (
  <LoadingPanelContainer
    $height={height}
    $fullHeight={fullHeight}
    $noBorder={noBorder}
    testId={testId}
    $backgroundColor={backgroundColor}
  >
    <CircularProgress
      size={20}
      data-testid={testId ? `${testId}-loadingPanel` : `loadingPanel`}
    />
  </LoadingPanelContainer>
);

export default LoadingPanel;
