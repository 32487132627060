import React, { AriaAttributes } from 'react';

import styled, { css } from 'styled-components';
import { ButtonSize, ButtonVariant, ButtonVariantCSS } from '.';
import useTracking, { TrackingEventData } from '../../../hooks/useTracking';
import { EventNames } from '../../../utils/trackingEvents';

const IconButtonSizeCSS = css<{ $size?: ButtonSize }>`
  ${({ $size }) => {
    switch ($size) {
      case ButtonSize.ExtraSmall:
        return css`
          height: 20px;
          width: 20px;
        `;
      case ButtonSize.Small:
        return css`
          height: 32px;
          width: 32px;
        `;
      case ButtonSize.Medium:
        return css`
          height: 40px;
          width: 40px;
        `;
      case ButtonSize.Large:
        return css`
          height: 56px;
          width: 56px;
        `;
      default:
        return ``;
    }
  }}
`;

export const IconButtonCSS = css<{
  $size?: ButtonSize;
  $variant?: ButtonVariant;
}>`
  ${IconButtonSizeCSS}
  ${ButtonVariantCSS}

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  font-size: 13px;
  padding: 0;

  border: none;
  cursor: pointer;
`;

const StyledIconButton = styled.button<{
  $size?: ButtonSize;
  $variant?: ButtonVariant;
}>`
  ${IconButtonCSS}
`;

interface IconButtonProps {
  icon?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  testId?: string;
  ariaLabel?: string;
  size?: ButtonSize;
  ariaControls?: AriaAttributes['aria-controls'];
  ariaHasPopup?: AriaAttributes['aria-haspopup'];
  disabled?: boolean;
  variant?: ButtonVariant;
  trackingData?: TrackingEventData;
}

export const IconButton: React.FC<IconButtonProps> = ({
  icon,
  onClick,
  testId,
  ariaLabel,
  size = ButtonSize.Medium,
  ariaControls,
  ariaHasPopup,
  disabled,
  variant = ButtonVariant.Outline,
  trackingData,
}) => {
  const { track } = useTracking();
  const onClickWithTracking = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (trackingData) {
      track(EventNames.ButtonClick, trackingData);
    }
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <StyledIconButton
      onClick={onClickWithTracking}
      data-testid={testId}
      aria-label={ariaLabel}
      aria-controls={ariaControls}
      aria-haspopup={ariaHasPopup}
      disabled={disabled}
      $size={size}
      $variant={variant}
      data-size={size}
      data-variant={variant}
      type="button"
    >
      {icon}
    </StyledIconButton>
  );
};
