import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  use,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import noop from 'lodash/noop';
import { Snackbar, SnackbarVariant } from '.';

interface SnackbarData {
  message: string;
  icon?: IconProp;
  variant?: SnackbarVariant;
  actionText?: string;
  onAction?: () => void;
  testId?: string;
}

interface ISnackbarContext {
  snackbar: SnackbarData | null;
  setSnackbar: Dispatch<SetStateAction<SnackbarData | null>>;
}

const initialState: SnackbarData | null = null;

const Context = createContext<ISnackbarContext>({
  snackbar: initialState,
  setSnackbar: noop,
});

const SnackbarContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [snackbar, setSnackbar] = useState<SnackbarData | null>(initialState);

  const context = useMemo(
    () => ({
      snackbar,
      setSnackbar,
    }),
    [snackbar],
  );

  useEffect(() => {
    if (!snackbar) {
      return;
    }

    // Wait six seconds to remove snackbar
    setTimeout(() => {
      setSnackbar(null);
    }, 6000);
  }, [snackbar]);

  return (
    <Context.Provider value={context}>
      {children}
      <Snackbar
        open={Boolean(snackbar)}
        message={snackbar?.message || ''}
        actionText={snackbar?.actionText}
        onAction={snackbar?.onAction}
        variant={snackbar?.variant}
        icon={snackbar?.icon}
        testId={snackbar?.testId}
      />
    </Context.Provider>
  );
};

export const useSnackbarContext = () => use(Context);

export default SnackbarContextProvider;
