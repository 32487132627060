import React from 'react';

import { ApolloLink, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';
import { PrinceSdkProvider } from '@teamexos/prince-sdk';
import { SentryLink } from 'apollo-link-sentry';
import fastRedact from 'fast-redact';
import { clientOptions } from '../apollo/client';

// Coach Hub currently does not have build numbers like the apps but we need to send
// up something until we roll out a forced app upgrade for coach hub
const buildNumber = '1';

const redactObjects = {
  paths: [
    '*.email',
    '*.photo',
    '*.avatar',
    '*.cronofyElementToken',
    '*.firstName',
    '*.lastName',
    '*.name',
    '*.userName',
    '*.messages',
    '*.text',
    '*.photos',
    '*.note',
    '*.signedUrl',
    '*.birthday',
    '*.pronouns',
    '*.age',
    '*.profileJson',
    '*.token',
    '*.iterableToken',
    '*.additionalInfo',
    '*.senderName',
    '*.coachFirstName',
    '*.coachAssignmentFirstName',
    '*.coachAssignmentLastName',
    '*.content',
    '*.planItems[*].description',
    'email',
    'photo',
    'avatar',
    'cronofyElementToken',
    'firstName',
    'lastName',
    'name',
    'userName',
    'messages',
    'text',
    'photos',
    'note',
    'signedUrl',
    'birthday',
    'pronouns',
    'age',
    'profileJson',
    'token',
    'iterableToken',
    'additionalInfo',
    'senderName',
    'coachFirstName',
    'coachAssignmentFirstName',
    'coachAssignmentLastName',
    'content',
  ],
};

const redact = fastRedact(redactObjects);

export const errorLink = onError(
  ({ operation, graphQLErrors, networkError }) => {
    // based off of https://dev.to/namoscato/graphql-observability-with-sentry-34i6

    graphQLErrors?.forEach((error) => {
      datadogRum.addError(new Error(error.message), {
        operationName: operation.operationName,
        releaseSHA: process.env.GATSBY_COMMIT_ID,
        variables: redact(operation.variables),
        extensions: operation.extensions,
        apolloGraphQLError: {
          path: error.path,
          message: error.message,
          extensions: error.extensions,
        },
      });
    });

    if (networkError) {
      if (networkError.stack?.includes('hammerhead.js')) {
        // filter testcafe e2e noise
        return;
      }
      datadogRum.addError(new Error(networkError.message), {
        operationName: operation.operationName,
        releaseSHA: process.env.GATSBY_COMMIT_ID,
        variables: redact(operation.variables),
        extensions: operation.extensions,
      });
    }

    Sentry.withScope((scope) => {
      scope.setTransactionName(operation.operationName);
      scope.setContext('apolloGraphQLOperation', {
        operationName: operation.operationName,
        releaseSHA: process.env.GATSBY_COMMIT_ID,
        variables: redact(operation.variables),
        extensions: operation.extensions,
      });

      graphQLErrors?.forEach((error) => {
        Sentry.captureMessage(error.message, {
          level: 'error',
          fingerprint: ['{{ default }}', '{{ transaction }}'],
          contexts: {
            apolloGraphQLError: {
              path: error.path,
              message: error.message,
              extensions: error.extensions,
            },
          },
        });
      });

      if (networkError) {
        if (networkError.stack?.includes('hammerhead.js')) {
          // filter testcafe e2e noise
          return;
        }
        Sentry.captureMessage(networkError.message, {
          level: 'error',
          contexts: {
            apolloNetworkError: {
              error: networkError,
              extensions: (networkError as any).extensions,
            },
          },
        });
      }
    });
  },
);

const PrinceSDKProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const headersLink = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        'x-application-version': `web/${buildNumber}/${process.env.GATSBY_COMMIT_ID}`,
        'x-application-name': 'prince-web',
        'x-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    });
    return forward(operation);
  });

  const sentryLink = new SentryLink();
  const retryLink = new RetryLink();

  const link = from([headersLink, sentryLink, errorLink, retryLink]);

  return (
    <PrinceSdkProvider
      clientOptions={{
        ...clientOptions,
        extraLink: link,
      }}
    >
      {children}
    </PrinceSdkProvider>
  );
};

export default PrinceSDKProvider;
