import { createTheme } from '@mui/material/styles';
import './styles/fonts.css';

// Blue
export const BLUE050 = '#E7E8F0';
const EXOS_BLUE = '#201C52'; // Blue 800

// Bright blue
const BRIGHTBLUE300 = '#02E6ED';

// Red
export const EXOS_RED = '#E68283'; // Red 300
export const RED050 = '#FEEDF0'; // Red 050
export const RED800 = '#CC413E'; // Red 800
export const RED900 = '#BD3732'; // Red 900
export const STATUS_ERROR = RED900;

// Gray (Steel)
export const EXOS_GRAY = '#F2F2F2'; // Gray 050
export const GRAY100 = '#E9E9E9';
export const GRAY200 = '#E5EAEF';
export const SECONDARY_DISABLED = '#D9D9D9'; // Background divider, secondary disabled, gray 200
export const GRAY300 = '#C4C4C4';
export const GRAY400 = '#9D9D9D';
export const PRIMARY_DISABLED = '#7B7B7B';
export const GRAY600 = '#555555';
export const WHITE = '#FFFFFF';
export const BLACK = '#000000';

// Cool Gray
export const COOL_GRAY100 = '#F2F4F6';
export const COOL_GRAY300 = '#D8E1E7';
export const COOL_GRAY400 = '#B9C2CA';
export const COOL_GRAY700 = '#46525C';
export const COOL_GRAY800 = '#323A41';

// Neutral
export const NEUTRAL50 = '#c2c2c2';
export const NEUTRAL70 = '#757575';

// Aquamarine
export const GREEN050 = '#E0F9F0';
export const GREEN100 = '#B3EFD9';
export const GREEN200 = '#7BE5C1';
const GREEN300 = '#00DBA8';
export const GREEN400 = '#00D294';
const GREEN700 = '#00a466';
export const GREEN900 = '#007140';

export const AQUAMARINE = GREEN300;

// Trend & Feed
const SLEEP_PURPLE = '#5827C0';
const TRAINING_PLAN_PURPLE = '#5827C0';
const TRAINING_PLAN_BLUE = '#029EF6';
const TRAINING_PLAN_LIGHT_ORANGE = '#FEB964';
const MONITORING_GRAY_PURPLE = '#9BA0C2';

export const TREND_COLORS = {
  TRAINING_PLAN: TRAINING_PLAN_PURPLE,
  CLASSES: TRAINING_PLAN_BLUE,
  OTHER: TRAINING_PLAN_LIGHT_ORANGE,
  PROGRAM: AQUAMARINE,
  PRACTICE: BRIGHTBLUE300,
  MONITORING: MONITORING_GRAY_PURPLE,
  SLEEP: SLEEP_PURPLE,
  ASSESSMENT: GREEN700,
};

// Non-palette colours
export const SECONDARY_HOVER_GRAY = '#F0F0F0';
export const TEXT_DISABLED = '#6F6F6F'; // Text disabled, icon disabled
export const BORDER_GRAY = GRAY200; // Border
export const ICON_SECONDARY = '#62737D';
export const BACKGROUND = '#FBFCFE';
export const HOVER = COOL_GRAY100;
export const BLACK2 = '#201C21'; // Text primary, icon primary
export const PLACEHOLDER = '#677582'; // Text placeholder
export const ICON_DISABLED = '#69757F';

export const DISABLED_OPACITY = 0.4;

// Focus Outline
export const FOCUS_OUTLINE = `2px solid ${BLACK}`;

// Box Shadow
export const BOX_SHADOW_2 = '0px 4px 8px 0px rgba(0, 0, 0, 0.15)';
export const BOX_SHADOW_3 = '0px 4px 4px 0px rgba(0, 0, 0, 0.25)';

const theme = createTheme({
  palette: {
    primary: {
      main: AQUAMARINE,
    },
    secondary: {
      main: EXOS_BLUE,
      contrastText: WHITE,
    },
    success: {
      main: AQUAMARINE,
    },
    error: {
      main: STATUS_ERROR,
    },
    background: {
      default: SECONDARY_HOVER_GRAY,
      paper: WHITE,
    },
    text: {
      primary: BLACK,
      secondary: BLACK,
    },
  },
  typography: {
    fontFamily: 'reader', // use reader font everywhere except titles
    body1: {
      fontFamily: 'reader',
      fontSize: 16,
      fontWeight: 400,
    },
    body2: {
      fontFamily: 'reader',
      fontSize: 14,
      fontWeight: 400,
    },
    button: {
      fontFamily: 'reader',
      fontSize: 16,
      fontWeight: 600,
      color: EXOS_BLUE,
      textTransform: 'uppercase',
    },
    overline: {
      fontFamily: 'reader',
      fontSize: 12,
      fontWeight: 400,
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          '& .MuiCircularProgress-circle': {
            stroke: BLACK2,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: 'reader',
          fontWeight: 400,
          fontSize: 12,
          padding: '4px 8px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 600,
          fontSize: 14,
          color: 'black',
          opacity: '0.54',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        text: {
          fill: WHITE,
        },
        root: {
          color: GRAY400,
          '&$active': {
            color: EXOS_BLUE,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&$focused $notchedOutline': {
            borderColor: EXOS_BLUE,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&$focused': {
            color: EXOS_BLUE,
          },
        },
      },
    },
    // The MuiContainer is the container for the entire screen. Added this to the theme so that we don't have to specify a background color every time we create a new screen.
    MuiContainer: {
      styleOverrides: {
        root: {
          backgroundColor: BORDER_GRAY,
          padding: 0,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        dot: {
          width: 14,
          height: 14,
          borderRadius: 7,
          backgroundColor: AQUAMARINE,
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxSizing: 'border-box',
          paddingLeft: 20,
          paddingRight: 20,
        },
      },
    },
  },
});

export default theme;
