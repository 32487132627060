import React from 'react';

import { ThemeProvider } from '@mui/system';
import {
  SplitFactory,
  SplitFactoryProvider,
} from '@splitsoftware/splitio-react';
import { AuthProvider } from '@teamexos/fit-shared';
import { ConfettiContextProvider } from '../components/reusable/Confetti';
import { GlobalAlerts } from '../components/reusable/Snackbar/GlobalAlert';
import SnackbarContextProvider from '../components/reusable/Snackbar/SnackbarContextProvider';
import theme from '../theme';
import PrinceSDKProvider from './PrinceSDK';
import TrackingEventListProvider from './TrackingEventListProvider';

interface Props {
  children?: React.ReactNode;
}

const getSplitFactory = () => {
  if (
    process.env.GATSBY_SPLIT_IO_AUTH_KEY === undefined ||
    process.env.GATSBY_SPLIT_IO_KEY === undefined
  ) {
    throw new Error(`environment variable for split io is not set`);
  }

  return SplitFactory({
    core: {
      // These are client side keys
      authorizationKey: process.env.GATSBY_SPLIT_IO_AUTH_KEY,
      key: process.env.GATSBY_SPLIT_IO_KEY,
    },
  });
};

/**
 * P R O V I D E R S
 */
const Providers: React.FC<Props> = ({ children }: Props) => {
  const sharedPlatformDomain = process.env.GATSBY_API_URL;

  if (!sharedPlatformDomain) {
    throw new Error('Missing sharedPlatformDomain');
  }

  return (
    <AuthProvider
      sharedPlatformDomain={sharedPlatformDomain}
      localSignOut={() => Promise.resolve()}
    >
      <PrinceSDKProvider>
        <SplitFactoryProvider
          factory={getSplitFactory()}
          attributes={{
            updateOnSdkUpdate: true,
          }}
        >
          <ThemeProvider theme={theme}>
            <SnackbarContextProvider>
              <ConfettiContextProvider>
                <TrackingEventListProvider>
                  {children}
                </TrackingEventListProvider>
                <GlobalAlerts />
              </ConfettiContextProvider>
            </SnackbarContextProvider>
          </ThemeProvider>
        </SplitFactoryProvider>
      </PrinceSDKProvider>
    </AuthProvider>
  );
};

export default Providers;
