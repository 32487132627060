import React from 'react';

import LoadingPanel from '../LoadingPanel';
import { StyledHeader } from './Header';

export const LoadingPage = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <StyledHeader />
    <LoadingPanel noBorder height={400} />
  </div>
);
