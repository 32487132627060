import React from 'react';

import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';
import useTracking, { TrackingEventData } from '../../../hooks/useTracking';
import {
  AQUAMARINE,
  BLACK,
  BORDER_GRAY,
  COOL_GRAY800,
  FOCUS_OUTLINE,
  PRIMARY_DISABLED,
  RED800,
  RED900,
  SECONDARY_DISABLED,
  SECONDARY_HOVER_GRAY,
  TEXT_DISABLED,
  WHITE,
} from '../../../theme';
import { EventNames } from '../../../utils/trackingEvents';
import { Spin } from '../Animations/Spin';
import TextComponent, { TextKind } from '../Text';

export enum ButtonIconPosition {
  Left = 'Left',
  Right = 'Right',
}

export enum ButtonSize {
  // Add additional sizes here
  Large = 'Large',
  Medium = 'Medium',
  Small = 'Small',
  ExtraSmall = 'ExtraSmall',
}

export enum ButtonVariant {
  // Add additional variants here
  Transparent = 'Transparent',
  Dark = 'Dark',
  Danger = 'Danger',
  Outline = 'Outline',
}

const ButtonSizeCSS = css<{ $size?: ButtonSize }>`
  border-radius: 99ch;

  ${({ $size }) => {
    switch ($size) {
      // Add additional sizes here
      case ButtonSize.ExtraSmall:
        return css`
          padding: 8px 12px;
          gap: 8px;
        `;

      case ButtonSize.Small:
        return css`
          padding: 8px 12px;
          gap: 8px;
        `;

      case ButtonSize.Large:
        return css`
          padding: 16px;
          gap: 12px;
          height: 56px;
        `;

      case ButtonSize.Medium:
      default:
        return css`
          padding: 8px 16px;
          gap: 12px;
        `;
    }
  }}
`;

export const ButtonVariantCSS = css<{
  $variant?: ButtonVariant;
  $size?: ButtonSize;
}>`
  ${({ $variant, $size }) => {
    switch ($variant) {
      case ButtonVariant.Transparent:
        return css`
          background: transparent;
          border: 1px solid transparent;
          border-radius: inherit;

          &:focus-visible {
            outline: ${FOCUS_OUTLINE};
          }

          &:active:not(:disabled),
          &:hover:not(:disabled) {
            background-color: ${SECONDARY_HOVER_GRAY};
          }
        `;
      // Add additional variants here
      case ButtonVariant.Dark:
        return css`
          background: ${BLACK};
          border: 1px solid transparent;
          color: ${WHITE};

          &:focus-visible {
            outline: ${FOCUS_OUTLINE};
          }

          &:hover {
            background: ${COOL_GRAY800};
          }

          &:disabled {
            background: ${PRIMARY_DISABLED};
          }
        `;
      case ButtonVariant.Danger:
        return css`
          background: ${RED800};
          border: 1px solid transparent;
          color: ${WHITE};

          &:focus-visible {
            outline: ${FOCUS_OUTLINE};
          }

          &:hover {
            background: ${RED900};
          }

          &:disabled {
            background: ${PRIMARY_DISABLED};
          }
        `;
      case ButtonVariant.Outline:
      default:
        return css`
          background: ${WHITE};
          border: 1px solid ${BORDER_GRAY};
          ${() => {
            switch ($size) {
              case ButtonSize.Large:
                return css`
                  border-radius: 56px;
                `;
              default:
                return css`
                  border-radius: 20px;
                `;
            }
          }}

          &:active,
          &:hover {
            background-color: ${SECONDARY_HOVER_GRAY};
          }
          &:focus-visible {
            outline: ${FOCUS_OUTLINE};
          }
          &:disabled {
            color: ${TEXT_DISABLED};
            background: ${SECONDARY_DISABLED};
          }
        `;
    }
  }}
`;

export const ButtonCSS = css<{
  $size?: ButtonSize;
  $variant?: ButtonVariant;
  $fullWidth?: boolean;
  $fullHeight?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-style: normal;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  ${({ $fullWidth }) => ($fullWidth ? `width: 100%;` : `width: auto;`)}
  ${({ $fullHeight }) =>
    $fullHeight ? `height: 100%;` : `height: min-content;`}

  ${ButtonSizeCSS}
  ${ButtonVariantCSS}
`;

const Container = styled.button<{
  $size: ButtonSize;
  $variant: ButtonVariant;
  $fullWidth: boolean;
  $fullHeight: boolean;
  $loading?: boolean;
}>`
  ${ButtonCSS}
  ${({ $loading }) =>
    $loading &&
    css`
      > :not(.loading) {
        visibility: hidden;
      }
    `}
`;

export const SIZE_TEXT_PROPS: {
  [key in ButtonSize]: React.ComponentProps<typeof TextComponent>;
} = {
  [ButtonSize.Large]: {
    kind: TextKind.ButtonL,
    style: { color: 'inherit', lineHeight: 1 },
  },
  [ButtonSize.Medium]: {
    kind: TextKind.ButtonL,
    style: { color: 'inherit', lineHeight: 1 },
  },
  [ButtonSize.Small]: {
    kind: TextKind.ButtonM,
    style: { color: 'inherit', lineHeight: 1 },
  },
  [ButtonSize.ExtraSmall]: {
    kind: TextKind.ButtonM,
    style: { color: 'inherit', lineHeight: 1 },
  },
};

interface ButtonProps {
  ref?: React.RefObject<HTMLButtonElement>;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  disabled?: boolean;
  onClick?:
    | (() => void)
    | ((event: React.MouseEvent<HTMLButtonElement>) => void);
  icon?: React.ReactNode;
  iconPosition?: ButtonIconPosition;
  size?: ButtonSize;
  variant?: ButtonVariant;
  fullWidth?: boolean;
  fullHeight?: boolean;
  testId?: string;
  ariaSelected?: boolean;
  ariaLabel?: string;
  type?: 'button' | 'submit';
  trackingData?: TrackingEventData & { eventName?: EventNames };
  loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  ref,
  children,
  disabled,
  onClick,
  icon: Icon = null,
  iconPosition = ButtonIconPosition.Right,
  size = ButtonSize.Medium,
  variant = ButtonVariant.Outline,
  fullWidth = false,
  fullHeight = false,
  testId,
  ariaSelected,
  ariaLabel,
  trackingData,
  type = 'button',
  loading,
  ...rest
}) => {
  const { track } = useTracking();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(e);
    }
    if (trackingData) {
      const { eventName, ...restTrackingData } = trackingData;
      track(eventName ?? EventNames.ButtonClick, restTrackingData);
    }
  };

  return (
    <Container
      {...rest}
      ref={ref}
      disabled={disabled}
      onClick={handleClick}
      type={type}
      data-testid={testId}
      data-size={size}
      data-variant={variant}
      $size={size}
      $variant={variant}
      $fullWidth={fullWidth}
      $fullHeight={fullHeight}
      aria-selected={ariaSelected}
      aria-label={ariaLabel}
      $loading={loading}
      data-loading={loading}
    >
      {iconPosition === ButtonIconPosition.Left ? Icon : null}
      {(() => {
        if (!children) {
          return null;
        }

        return (
          <TextComponent
            component="span"
            {...SIZE_TEXT_PROPS[size]}
            data-testid={`${testId}-children`}
          >
            {children}
          </TextComponent>
        );
      })()}
      {iconPosition === ButtonIconPosition.Right ? Icon : null}

      {loading && (
        <Spin
          className="loading"
          style={{ display: 'flex', position: 'absolute' }}
        >
          <FontAwesomeIcon
            icon={faSpinnerThird}
            fontSize={20}
            color={AQUAMARINE}
          />
        </Spin>
      )}
    </Container>
  );
};

export default Button;
