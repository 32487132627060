export enum SplitFlags {
  MOCK_FLAG = 'mock-flag', // for use in unit tests that require `SplitFlags` but that aren't coupled to a specific feature flag
  CH_UNREAD_COUNT_SUBSCRIPTIONS = 'ch-unread-count-subscriptions',
  CH_REPORT_EXTERNAL_SESSIONS = 'ch-report-external-sessions',
  CH_CONTENT_LIBRARY_FEEDBACK = 'ch-content-library-feedback',
  NS_HOME_PAGE = 'ch-nutrition-specialist-home',
  CH_MONDAY_GAMEPLANS = 'ch-monday-gameplans',
  CH_VIEW_SLEEP_FOR_WB_MEMBERS = 'ch-view-sleep-for-wb-members',
  CH_GAMEPLAN_EDITOR_KEYBOARD_SUPPORT = 'ch-gameplan-editor-keyboard-support',
  CH_SLEEP_AGGREGATES = 'ch-sleep-aggregates',
  CH_IMPROVE_CONTENT_LIBRARY_SEARCH = 'ch-improve-content-library-search',
  CH_CONTENT_LIBRARY_SEARCH_FEEDBACK = 'ch-content-library-search-feedback',
}
